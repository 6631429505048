@charset "utf-8";


/* =Reset default browser CSS.
Based on work by Eric Meyer: http://meyerweb.com/eric/tools/css/reset/index.html
-------------------------------------------------------------- */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table.table, caption, tbody, tfoot, thead, tr, th, td {border: 0;font-family: inherit;font-size: 100%;font-style: inherit;font-weight: inherit;margin: 0;outline: 0;padding: 0;vertical-align: baseline;}
:focus {outline: 0;}

ol, ul {list-style: none;}
table.table {border-collapse: separate;border-spacing: 0;}
caption, th, td {font-weight: normal;text-align: left;}
blockquote:before, blockquote:after,q:before, q:after {content: "";}
blockquote, q {quotes: "" "";}
a img{border: 0;}
figure{margin:0}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display: block;}
/* -------------------------------------------------------------- */

@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@500;600&display=swap');

body {
color:#333;
font-size:16px;
font-family:verdana,"ヒラギノ丸ゴ ProN W4","Hiragino Maru Gothic ProN","メイリオ","Meiryo","ＭＳ Ｐゴシック","MS PGothic",Sans-Serif;
line-height:1.5;
-webkit-text-size-adjust: none;
}



/* リンク設定
------------------------------------------------------------*/
a{color:#ed6639;
	text-decoration:none;
}
a:hover{opacity: 0.7}
// a:active, a:focus {outline:0;}


/* 全体
------------------------------------------------------------*/
#wrapper{
margin:0 auto;
padding:0 1%;
width:98%;
position:relative;
background:#fff;
}

.inner{
margin:0 auto;
width:100%;
}


/*************
/* ヘッダー
*************/
#header{
height:110px;
}

#header h1{
padding: 25px 0 10px 15px;
font-size:10px;
font-weight:normal;
}

/*************
/* ロゴ
*************/
.logo{
float:left;
}

.logo p{float:left;}

.logo img{
width:340px;
height: auto;
float:left;
padding:2px 10px 0 10px;
}

.logo span{display:block;}

/*************
/* 電話番号
*************/
.info{
// float:right;
padding-right:20px;
text-align:right;
margin: 0 0 20px;
}

.info .open{font-size:11px;}


/*************
/* ロゴ+電話番号
*************/
.logo,.info .tel{
// margin-top:6px;
color:#ed6639;
font-size:20px;
font-weight:bold;
}

.logo span,.info span{
color:#333;
font-size:.6em;
font-weight:normal;
}


/**************************
/* メイン画像（トップページ+サブページ）
**************************/
#mainBanner{
	margin:17px auto;
	padding:0;
	width:960px;
	position:relative;
	line-height:0;
	box-shadow: 0px 10px 18px -4px rgba(0,0,0,0.8);
}

#mainBanner img{
	max-width:100%;
	width: 100%;
	height:auto;
}

// #subpage #mainBanner img{border:4px solid #f1f1f1;}

.slogan{
	position:absolute;
	max-width:100%;
	height:auto;
	bottom:20px;
	left:0;
	padding:5px 10px;
	line-height:1.4;
	color:#939393;
}

.slogan h2{
	padding-bottom:5px;
	font-size:20px;
	font-weight:bold;
	color:#333;
}


/**************************
/* グリッド
**************************/
.gridWrapper{
padding-bottom:20px;
overflow: hidden;
}

* html .gridWrapper{height:1%;}

.grid{
float:left;
border-radius:5px;
background:#fff;
}

.box{
margin:0 5px;
padding:10px;
border:1px solid #f1f1f1;
}

.box img{
max-width:100%;
height:auto;
border:4px solid #f1f1f1;
}

.box h3{padding:10px 0 5px;}

.box h3, .box p{text-align:left;}

.box p.readmore{
padding:10px 5px 5px;
text-align:right;
}



/*************
メイン コンテンツ
*************/
section.content article{
padding:20px;
margin-bottom:20px;
border:1px solid #f1f1f1;
overflow:hidden;
strong{
	font-weight: bold;
	color: #000;
}
	a{
		text-decoration: underline;

	}
}

* html section.content article{height:1%;}

section.content p{margin-bottom:5px;}

h3.heading{
font-weight:normal;
padding:0 10px;
// line-height:36px;
font-size: 20px;
padding: 5px 10px 5px;
color:#fff;
background:#ed6639;
}

section.content img{
max-width:90%;
height:auto;
}

.alignleft{
float:left;
clear:left;
margin:3px 10px 10px 0;
}

.alignright{
float:right;
clear:right;
margin:3px 0 10px 10px;
}

#gallery .grid img,.border,ul.list img{border:4px solid #f1f1f1;}



/*************
テーブル
*************/
table.table{
border-collapse:collapse;
margin:5px auto 15px auto;
}

table.table td{
border-collapse:collapse;
background-color:#F9F9F9;
text-align:left;
padding:8px;
border:1px solid #D6D6D6;
line-height:20px;
}

table.table th{
background-color:#F5F5F5;
// text-align:right;
padding:8px;
white-space:nowrap;
color: #151515;
border-top:1px solid #D4D4D4;
border-right:4px solid #D4D4D4;
border-bottom:1px solid #D4D4D4;
border-left:1px solid #D4D4D4;
font-size:13px;
font-weight:normal;
letter-spacing:1px;
background-repeat:repeat-x;
background-position:top;
}


/*************
/* サイドバー
*************/
#sidebar ul{padding:10px;}

#sidebar li{
margin-bottom:10px;
padding-bottom:10px;
list-style:none;
border-bottom:1px dashed #f1f1f1;
}

#sidebar li:last-child{
border:0;
margin-bottom:0px;
}

#sidebar li a{
	display:block;
	text-decoration: underline;
}

#sidebar ul.list li{
clear:both;
margin-bottom:5px;
padding:5px 0;
overflow:hidden;
border:0;
}

ul.list li{height:1%;}

ul.list li img{
float:left;
margin-right:10px;
}

ul.list li{
font-size:12px;
line-height:1.35;
}


/*************
/* フッター
*************/
#footer{
clear:both;
overflow:hidden;
padding:10px 10px 20px 10px;
background:#f3f3f3;
}

* html #footer{height:1%;}

#footer .grid{
padding:10px 0 5px;
margin:0;
background:transparent;
}

#footer #info .logo, #footer #info .info{float:none;}

#footer #info .info{
clear:both;
padding:10px 0 0 15px;
text-align:left;
}

// #footer .logo{font-size:18px;}

// #footer .info .tel,#footer .info .tel span,#footer #info .open{
// font-size:12px;
// color:#333;
// }

ul.footnav{
float:right;
width:650px;
padding:10px 0;
}

ul.footnav li{
float:left;
margin-bottom:10px;
padding:0 7px 0 8px;
font-size:11px;
border-right:1px solid #b6b6b6;
}

// #footer a{color:#333;}
// #footer a:hover{color:#888;}

address{
clear:both;
padding:5px;
text-align:center;
font-style:normal;
font-size:10px;
color:#333;
background:#dbdbdb;
}


/* PC用
------------------------------------------------------------*/
@media only screen and (min-width: 960px){
	#header,#wrapper,.inner{
	width:960px;
	padding: 5px 0 0;
	margin:0 auto;
	}

	#wrapper{padding-bottom:20px;}

  nav#mainNav{
	clear:both;
	// overflow:hidden;
	position:relative;
	}

	nav#mainNav .inner{
	width:932px;
	padding:0 14px;
	}
	nav#mainNav ul{
		width: 100%;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
	}
	nav#mainNav ul li{
	// float: left;
	border-left:1px dotted #ebebeb;
	position: relative;
	width: 25%;
	}

	nav#mainNav li:last-child{border-right:1px dotted #ebebeb;}

	nav#mainNav ul li a{
	display: block;
	text-align: center;
	_float:left;
	// font-size:13px;
	width:100%;
	height:28px;
	padding-top:9px;
	color:#333;
	transition: 0.4s ease;
	}

	nav#mainNav ul li a span,nav#mainNav ul li a strong{
	display:block;
	}

	nav#mainNav ul li a span{
	color:#cdcdcd;
	font-size:10px;
	}

	nav div.panel{
	display:block !important;
	// float:left;
	}

	a#menu{display:none;}

	nav#mainNav li.active > a, nav#mainNav li > a:hover{
	// color:#fff;
	// opacity: 1;
  // background:#ed6639;
	// padding-top:9px;
	}

	nav#mainNav .panel li > a:hover{
		background-color: #c1c1c1;
	}

	// #mainBanner{margin-left:15px;}
	#subpage #mainBanner{margin:17px auto;}

	#main{
		box-sizing: border-box;
	float:right;
	width:667px;
	padding-right:15px;
	margin: 17px 0 100px;
	}

	#sidebar{
	float:left;
	width:245px;
	padding:17px 0 0 15px;
	overflow:hidden;
	}

	#sidebar article{
	padding:7px;
	margin-bottom:20px;
	border:1px solid #f1f1f1;
	overflow:hidden;
	}

	/* グリッド全体 */
	.gridWrapper{
	width:960px;
	margin:0 0 0 -10px;
	}

	#gallery article{padding:10px 0 10px 10px;}

	/* グリッド */
	.grid{
	float:left;
	width:300px;
	margin-left:20px;
	}

	#subpage .grid{width:auto;}

  #gallery .grid{
	width:auto;
	margin:10px 0 2px 10px;
	}
}


@media only screen and (max-width:959px){
	*{
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
         -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
            box-sizing: border-box;
	}

	nav#mainNav{
	clear:both;
	width:100%;
	margin:0 auto;
	padding:0;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(1, #f5f5f5), color-stop(0.5, #f0f0f0), color-stop(0.00, #f7f7f7));
	background: -webkit-linear-gradient(to top, #f7f7f7 0%, #f0f0f0 50%, #f5f5f5 100%);
	background: -moz-linear-gradient(to top, #f7f7f7 0%, #f0f0f0 50%, #f5f5f5 100%);
	background: -o-linear-gradient(to top, #f7f7f7 0%, #f0f0f0 50%, #f5f5f5 100%);
	background: -ms-linear-gradient(to top, #f7f7f7 0%, #f0f0f0 50%, #f5f5f5 100%);
	background: linear-gradient(to top, #f7f7f7 0%, #f0f0f0 50%, #f5f5f5 100%);
  border:1px solid #e4e4e4;
	}

	nav#mainNav a.menu{
	width:100%;
	display:block;
	height:40px;
	line-height:40px;
	font-weight: bold;
	text-align:left;
	}

	nav#mainNav a#menu span{padding-left:10px;}
	nav#mainNav a.menu span:before{content: "→ ";}
	nav#mainNav a.menuOpen span:before{content: "↓ ";}

	nav#mainNav a#menu:hover{cursor:pointer;}

	nav .panel{
	display: none;
	width:100%;
	position: relative;
	right: 0;
	top:0;
	z-index: 1;
	}

	nav#mainNav ul li{
	float: none;
	clear:both;
	width:100%;
	height:auto;
	line-height:1.2;
	}

	nav#mainNav ul li a,nav#mainNav ul li.current-menu-item li a{
	display: block;
	padding:15px 10px;
	text-align:left;
	border-bottom:1px dashed #ccc;
	}

	nav#mainNav ul li a span{padding-left:10px;}

	nav#mainNav ul li:first-child a{
	border-top:1px solid #e4e4e4;
	}

	nav#mainNav ul li:last-child a{border:0;}

	nav#mainNav ul li.active a{color:#000;}

	nav div.panel{float:none;}

	#subpage #mainBanner{max-width:668px;}

	#sidebar{padding-bottom:30px;}

	#sidebar article{padding:7px 0;}

	#footer .grid,#footer ul.footnav{
	float:none;
	width:100%;
	text-align:center;
	}

	ul.footnav li{float:none;display:inline-block;}

	.grid{
	width:32%;
	margin:10px 0 0 1%;
	}

	#gallery .grid{
	float:left;
	width:31%;
	margin:0 0 0 1%;
	}

	#footer .grid img, #footer .info{float:none;}
	#footer .grid p{display:block;}
	#footer #info .info{float:none;text-align:center;}

	#footer .logo,#footer .info{
	width:100%;
	float:none;
	}

	#footer .logo p{float:none;display:block;}
	#footer .logo img{
		float:none;
		padding:0;

	}
}



/* スマートフォン 横(ランドスケープ) */
@media only screen and (max-width:640px){
	#header{
	height:auto;
	padding-bottom:10px;
	}

	#header h1,.logo,.info{text-align:center;}

	.logo,.info{
	clear:both;
	width:100%;
	float:none;
	}

	.logo p{float:none;display:block;}
	.logo img{float:none;padding:0;
	width: 180px;}

	.box img{
	float:left;
	margin-right:5px;
	}

	.box h3{padding-top:0;}

  .alignleft,.alignright{
	float:none;
	display:block;
	margin:0 auto 10px;
	}

	#gallery .grid{float:left;}
}


/* スマートフォン 縦(ポートレート) */
@media only screen and (max-width:480px){
	.grid{width:100%;}
	.box img{max-width:100px;}
	#mainBanner h2,#mainBanner p{font-size:80%;}
}













// 改修CSS
.map-box{
	ul{
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		li{
			width: 50%;
			p{
				text-align: center;
				// margin: 0 0 5px;
				padding: 2px 0;
				color: #fff;
				background-color: #ed6639;
			}
			&:nth-of-type(1){
				margin: 0 20px 0 0;
			}
		}
	}
}


.js-header{
	height: 241px;
}
.header-wrap{
	width: 100vw;
	transition: 0.4s ease;
	background-color: #fff;
	// position: absolute;
	top: 0;
	// left: 50%;
	// transform: translateX(-50%);
	box-shadow: 0px 5px 18px -4px rgba(0,0,0,0.2);
	&.is-fixed{
		width: 100vw;
		top: -40px;
		// left: 50%;
		// transform: translateX(-50%);
		position: fixed;

		.contact-title{
			display: none;
		}

		.contact-item{
			width: 165px;
			a{
				bottom: 12px;
				width: 90%;
			}
		}


		header#header{
			height: 100px;
			margin: 0 auto 5px;
		}
	}
}
header#header{
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
	justify-content: space-between;
  margin: 13px auto 40px;
	transition: 0.4s ease;
}
.band{
	width: 100%;
	background-color: #ed6639;
	padding: 5px 0;
	p{
		width: 960px;
		margin: auto;
		color: #fff;
	}
}


.contact{
	// padding: 0 0 20px;
}
.contact-title{
	width: 360px;
	margin: auto;
	img{
		width:100%;
		max-width: 100%;
	}
}
.contact-inner{
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
}
.contact-item{
	transition: 0.4s ease;
	width: 195px;
	height: 112px;
	position: relative;
	&:nth-of-type(1){
		background-image: url(/assets/images/contact_bg01.png);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		margin: 0 5px 0 0;
	}
	&:nth-of-type(2){
		background-image: url(/assets/images/contact_bg02.png);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
	}
}

.contact-item{
	a{
		display: block;
		width: 180px;
		// max-width: 180px;
		position: absolute;

		&:nth-of-type(1){
			top: 39px;
			bottom: auto;
			left: 50%;
			transform: translateX(-50%);
		}
		&:nth-of-type(2){
			top: auto;
			bottom: 1px;
			left: 50%;
			transform: translateX(-50%);
		}
		img{
			width: 100%;
			max-width: 100%;
		}
	}
}



.message-box{
	width: 960px;
	margin: 0 auto 40px;
	ul{
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		flex-wrap: wrap;
		li{
			height: 320px;
			width: 50%;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;
			position: relative;

			p{
				position: absolute;
				top: 70px;
			}
			&:nth-of-type(1){
				background-image: url(/assets/images/message01.png);
			}
			&:nth-of-type(2){
				background-image: url(/assets/images/message02.png);
			}
			&:nth-of-type(3){
				background-image: url(/assets/images/message03.png);
			}
			&:nth-of-type(4){
				background-image: url(/assets/images/message04.png);
			}
		}
	}
}


.top{
	#mainNav{
		.panel{
			& > ul{
				& >	li{
					&:nth-of-type(1){
						& > a{
							border-bottom: 2px solid #ed6639;
						}
					}
				}
			}
		}
	}
}

.greeting{
	#mainNav{
		.panel{
			& > ul{
				& >	li{
					&:nth-of-type(2){
						& > a{
							border-bottom: 2px solid #ed6639;
						}
					}
				}
			}
		}
	}
}

.item-page{
	#mainNav{
		.panel{
			& > ul{
				& >	li{
					&:nth-of-type(3){
						& > a{
							border-bottom: 2px solid #ed6639;
						}
					}
				}
			}
		}
	}
}
.first{
	#mainNav{
		.panel{
			& > ul{
				& >	li{
					&:nth-of-type(4){
						& > a{
							border-bottom: 2px solid #ed6639;
						}
					}
				}
			}
		}
	}
}
.access{
	#mainNav{
		.panel{
			& > ul{
				& >	li{
					&:nth-of-type(5){
						& > a{
							border-bottom: 2px solid #ed6639;
						}
					}
				}
			}
		}
	}
}



.u-flex{
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
	img{
		min-width: 190px;
		margin: 0 0 0 15px;
		& + p{
			text-align: center;
			font-size: 13px;
		}
	}
}
.font-small{
	font-size: 13px;
}
.font-bold{
	font-weight: 900;
}

#slick{

}

.slick-dots{
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
	justify-content: center;
	position: absolute;
	// top: -23px;
	left: 50%;
	transform: translateX(-50%);
	margin: -23px auto 0;
	// z-index: 100;
	li{
		// color:#ed6639;
		margin: 0 10px 0 0;
		background-color: #ed6639;
		// background-color: #fff;
		border-radius: 50%;

		width: 12px;
		height: 12px;
		button{
			color: transparent;
			border: none;
			background-color: #ed6639;
			opacity: 0;
		}
		&.slick-active{
			background-color: #fff;
			button{
				background-color: #fff;
			}
		}
	}
}

.news-box{
	width: 960px;
	box-sizing: border-box;
	margin: 40px auto 40px;
	h2{
		font-weight: bold;
		font-size: 24px;
		border-left: 6px solid #ed6639;
		padding: 0 0 0 10px;
		margin: 0 0 20px;
	}
	a{
		text-decoration: underline;
	}
}
.news-content{
	border: 1px solid #f1f1f1;
	padding: 20px;
}
.news-item{
	margin: 0 0 15px;
	border-bottom: 1px solid #f1f1f1;
	padding: 0 0 15px;
	color: #ed6639;
	&:last-of-type{
		border:none;
		padding: 0;
		margin: 0;
	}
}

.fixed-banner{
	display: none;
	position: fixed;
	transform: translateY(120px);
	bottom: 0;
	right: 0;
	width: 100%;
	transition: 0.4s ease;
	background: rgba(0,0,0,0.4);
	// box-shadow: 0px -5px 16px -6px rgba(0,0,0,0.6);
	padding: 15px 0;
	&.is-show{
		transform: translateY(0px);
	}
	ul{
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
	}
	li{
		width: 50%;
		// margin: 0 0 20px;
		a{
			background-color: #ed6639;
			display: block;
			margin: auto;
			padding: 10px 75px 10px 20px;
			width: 360px;
			border-radius: 50px;
	    box-sizing: border-box;
			color: #fff;
			font-weight: bold;
			font-size: 18px;
			transition: 0.4s ease;
			background-size: 100px;
			background-repeat: no-repeat;
			background-position: right 10px center;
			border: 2px solid #fff;
			&:hover{
				background-color: #fff;
				color: #ed6639;
				border: 2px solid #ed6639;
				opacity: 1;
			}
		}

		&:nth-of-type(1){
			border-right: 2px solid #fff;
			a{
				background-image: url(/assets/images/fixed_banner01.jpg);
			}
		}
		&:nth-of-type(2){
			a{
				background-image: url(/assets/images/fixed_banner02.jpg);
			}
		}
	}
}

.note-box{
	width: 960px;
	margin: 40px auto 40px;
	box-sizing: border-box;
	padding: 10px;
	border: 2px solid #f00;
	p{
		text-align: center;
		a{
			font-size: 20px;
			transition: 0.4s ease;
			color: #f00;
			text-decoration: underline;
		}
	}
}

.dr-box{
	width: 930px;
	margin: 0 auto 40px;
	h2{
		font-weight: bold;
		font-size: 18px;
		margin: 0 0 10px;
		padding: 0 0 5px;
		border-bottom: 2px solid #ed6639;
	}
}
.dr-list{
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
	img{
		width: 100%;
		max-width: 100%;
	}
}
.dr-item{
	margin: 0 20px 0 0;
	a{
		transition: 0.4s ease;
	}
	&:last-of-type{
		margin: 0;
	}
}


.inplant-box{
	margin: 40px auto 40px;

	h2{
		font-weight: bold;
		font-size: 16px;
		margin: 0 0 15px;
		padding: 0 0 5px;
		border-bottom: 2px solid #ed6639;
	}
}

.inplant-list{
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
	flex-wrap: wrap;
	img{
		width: 100%;
		max-width: 100%;
	}
}
.inplant-item{
	margin: 0 20px 0 0;
	a{
		transition: 0.4s ease;
	}
	&:last-of-type{
		margin: 0;
	}
}


.top{
	.inplant-box{
		margin: 0 auto 40px;
		h2{
			font-size: 18px;
		}
	}
	.inplant-list{
		flex-wrap: nowrap;
	}
	.inplant-item{
		margin: 0 0 20px;
	}
}



.banner-list{
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
	width: 960px;
	box-sizing: border-box;
	margin: 0 auto 40px;
}
.banner-item{
	width: 17%;
	margin: 0 20px 0 0;
	img{
		width: 100%;
		max-width: 100%;
	}
}



nav#mainNav{
	.panel{
		li{
			position: relative;
			& > a{
				position: relative;
				z-index: 10;
			}
			.megamenu{
				width: 100%;
				// padding: 20px;
				background-color: #ed6639;
				position: absolute;
				top: 35px;
				// bottom: 0px;
				z-index: 100;
				opacity: 0;
				transition: 0.4s ease;
				pointer-events: none;
				// min-height: 500px;
				ul{
					flex-direction: column;
					border: none;
				}
				li{
					width: 100%;
					// height: 30px;
					border: none;
					border-bottom: 1px solid #fff;
				}
				a{
					// height: 100%;
					display: block;
					width: 100%;
					padding: 10px 0;
					color: #fff;
					transition: 0.4s ease;
					&:hover{
						color: #ed6639;
						opacity: 1;
						background-color: #fff;
					}
				}
			}
			.is-megamenu-active{
				pointer-events: auto;
				opacity: 1;
				top: 38px;
			}


		}
	}

}

.slogan{
	// padding: 30px;
	// background-color: #fff;
	// left: 50%;
	// transform: translateX(-50%);
  bottom: 110px;
  border-radius: 6px;
	// border: 2px solid #ed6639;
	p{
		font-family: 'Noto Serif JP', serif;
		font-size: 32px;
		// font-weight: bold;
		color: #fff;
		line-height: 2.25;
		// font-family: serif;
		span{
			background-color: #ed6639;
			padding: 2px 5px;
		}
	}
}
.message{
	img{
		display: block;
		text-align: center;
		margin: 20px auto;
	}
	margin: 80px 0;
	p{
		text-align: center;
		font-size: 16px;
	}
}


// #subpage{
	.js-header{
		margin: 0 0 30px;
		position: relative;
		z-index: 100;
		// box-shadow: 0px 5px 18px -4px rgba(0,0,0,0.2);
	}
	.content{
		padding: 204px 0 0;
		margin: -204px 0 0;
		h2{
			font-weight: bold;
			font-size: 20px;
			padding: 0 0 0 10px;
			border-left: 6px solid #ed6639;
			margin: 0 0 20px;
		}
	}
	.dr-box{
		width: 100%;
		img{
			max-width: 100%;
		}
	}
// }

.cb{
	clear: both;
}

.ci{
  clear: inherit;
}

.icon{
	border: 1px solid #ed6639;
	border-radius: 4px;
	padding: 2px 4px;
	display: inline-block;
	color: #ed6639;
	margin: 0 0 10px;
	font-weight: bold;
}

.breadcrumb{
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
	margin: 0 0 0 20px;
	li{
		margin: 0 20px 0 0;
	}
	a{
		position: relative;
		text-decoration: underline;
		color: #ed6639;

		&::after{
			content: '>';
			position: absolute;
			right: -15px;
			top: -1px;
			color: #000;
		}
	}
}
.current-page{
	color: #000!important;
	pointer-events: none!important;
	text-decoration: none!important;
}

.img-flex{
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
	justify-content: center;
	align-items: baseline;
	img{
		margin: 0 0 5px;
		// &:nth-of-type(1){
		// 	margin: 0 20px 0 0;
		// }
	}
	& > div{
		width: 50%;
		&:nth-of-type(1){
			margin: 0 20px 0 0;
		}
	}
}

.js-modal{
	cursor: pointer;
	transition: 0.4s ease;
	// width: 100%;
	margin: auto;
	&:hover{
		opacity: 0.7;
	}
}
.c-modal {
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  display: none;
  z-index: 9999;
  position: fixed;
  top: -10px;
  right: 0;
  bottom: -10px;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.45);
}

.c-modal__container {
  display: table;
  width: 100%;
  height: 100%;
  padding: 10px 0;
}

.c-modal__inner {
  display: table-cell;
  padding: 2.7em 2em;
  vertical-align: middle;
}

.c-modal__contents {
  position: relative;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  text-justify: inter-ideograph;
	img{
		display: block;
		margin: auto;
	}
}

.c-modal__contents::after {
  display: table;
  clear: both;
  content: '';
}

.c-modal__contents > p {
  line-height: 1.7;
  text-indent: 1em;
}

.c-modal__button__wrap {
  position: absolute;
  top: 20px;
  right: 20px;
}

.c-modal__button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  border: none;
  outline: none;
  background: transparent;
  line-height: 1;
  text-indent: -9999px;
  vertical-align: middle;
  cursor: pointer;
}

.c-modal__button::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-image: url(/assets/images/close.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  content: '';
}

.c-modal table {
  border-top: 1px solid #c8c8c8;
  border-collapse: collapse;
  color: #323030;
  font-size: 14px;
  line-height: 1.5;
}

.c-modal table tr {
  border-bottom: 1px solid #c8c8c8;
}

.c-modal table th {
  min-width: 110px;
  padding: 13px 13px 13px 13px;
  background-color: #f5f5f5;
  vertical-align: top;
}

.c-modal table td {
  width: 100%;
  padding: 15px 0 13px 30px;
  background-color: #fff;
  vertical-align: middle;
}

.c-modal table td ul li {
  list-style-type: disc;
}

.arrow{
	position: relative;
	top: 2px;
}


.content-item{
	padding: 20px 0 0;
	margin: 0 0 20px;
	h4{
		font-weight: bold;
		font-size: 18px;
		position: relative;
		margin: 0 0 20px;
		padding: 0 0 15px 15px;
		border-bottom: 1px solid #999;
		&::before{
			content: '';
			position: absolute;
			top: 33%;
			left: 0;
			transform: translateY(-50%);
			width: 5px;
			height: 5px;
			background-color: #ed6639;
		}
	}
}

.staff-list{
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
	flex-wrap: wrap;
	li{
		width: 18.65%;
		margin: 0 10px 0 0;
		&:last-of-type{
			margin: 0;
		}
		img{
			width: 100%;
			max-width: 100%;
		}
	}
}
.pos-point{
	position: relative;
	img{
		display: block;
	}
	p{
		position: absolute;
		right: 0;
		bottom: -20px;
	}
}

.arrow-list{
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
	align-items: end;
	li{
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		justify-content: center;
		align-items: baseline;
		flex-direction: column;
		width: 25%;
		margin: 0 12% 0 0;
		position: relative;
		&::after{
			content: '';
			position: absolute;
			// top: 50%;
			top: auto;
			bottom: 75px;
			// transform: translateY(-50%);
			right: -50px;
			width: 35px;
			height: 60px;
			background-repeat:no-repeat;
			background-size: contain;
			background-position: center center;
			background-image: url(/assets/images/change.gif);
		}
		&:nth-of-type(1){
			p{
				position: relative;
				bottom: -20px;
			}
		}
		&:last-of-type{
			margin: 0;
			&::after{
				display: none;
			}
		}
		img{
			display: block;
			text-align: center;
			margin: auto;
			// min-height: 120px;
			min-width: 90px;
			width: 100px;
			max-width: none;
		}
	}
}


.c-f00{
	color: #f00;
}
.ta-c{
	text-align: center;
	display: block;
	margin: auto;
}
.ta-r{
	text-align: right;
}

.implant-box{
	border: 2px solid #ed6639;
	padding: 20px;
	margin: 0 0 15px;
	h3{
		padding: 5px 10px;
		background-color: #fff;
		font-weight: bold;
		color: #ed6639;
		font-size: 20px;
		text-align: center;
		margin: -40px auto 10px;
		display: table;
	}
	p{
		font-size: 17px;
		span{
			margin: 5px 0 0;
			display: block;
			text-align: right;
			font-size: 14px;
		}
	}
}

.grid-two{
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
	justify-content: center;
	li{
		width: 35%;
		margin: 0 40px 0 0;
		&:last-of-type{
			margin: 0;
		}
	}
	&.grid-two-noimage{
		li{
			width: 50%;
			img{
				width: 100%;
				max-width: 100%;
			}
		}
	}
}
.qa-box{

	dl{
		border: 2px solid #ed6639;
		margin: 0 0 20px;
		position: relative;
		&::before{
			position: absolute;
	    top: 14px;
			z-index: 5;
      right: 14px;
	    width: 2px;
	    height: 16px;
	    margin: auto;
	    background: #fff;
	    content: "";
	    transition: .6s ease;
		}
		&::after{
			position: absolute;
	    top: 21px;
			z-index: 5;
	    right: 7px;
	    width: 16px;
	    height: 2px;
	    margin: auto;
	    background: #fff;
	    content: "";
	    transition: .6s ease;
		}

		&.is-open{
			&::before{
				transform: rotate(90deg);
			}
			&:after{
				transform: rotate(-180deg);
			}
		}
	}
	dt{
		position: relative;
		padding: 10px 22px 10px 45px;
		transition: 0.4s ease;
		color: #fff;
		background-color: #ed6639;

		cursor: pointer;
		&:hover{
			opacity: 0.7;
		}
		&::before{
			content: '';
			position: absolute;
			left: 5px;
			top: 50%;
			transform: translateY(-50%);
			width: 30px;
			height: 30px;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;
			background-image: url(/assets/images/icon_q.png);
		}
	}
	dd{
		position: relative;
		display: none;
		padding: 15px 15px 15px 45px;
		&::before{
			content: '';
			position: absolute;
			left: 5px;
			top:15px;
			width: 30px;
			height: 30px;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;
			background-image: url(/assets/images/icon_a.png);
		}
	}
}

.u-color{
	color: #ed6639;
	font-weight: bold;
}
.u-color-blue{
	color: #316ffd;
}

.font-big{
	font-size: 24px;
}



.time-table{
	color: #000;
	width: 100%;
	margin: 0 0 40px;
	p{
		text-align: center;
		margin: 0 0 5px;
	}
	table{
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;
	}
	thead{
		border-top: 1px solid #aaa;
		border-right: 1px solid #aaa;
		border-left: 1px solid #aaa;
		th{
			color: #fff;
			background-color: #ed6639;
			padding: 5px;
			border-right: 1px solid #fff;text-align: center;
			&:last-of-type{
				border-right: 1px solid #aaa;
			}
		}
	}

	tbody{
		td{
			padding: 10px;
			border: 1px solid #aaa;
			text-align: center;
		}
	}
}



section.content{
	.index-panel{
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		flex-wrap: wrap;
		// justify-content: center;
	}
	.index-panel-item{

		box-shadow: 0px 4px 16px -6px rgba(0,0,0,0.2);
		border: 1px solid #ccc;
		width: 30.9%;
		margin: 0 3% 30px 0;

		&:nth-of-type(3n){
			margin: 0 0 30px 0;
		}
		h3{
			color: #000;
			font-size: 20px;
			transition: 0.4s ease;
			margin: 0 0 10px;
		}
		a{
			transition: 0.4s;
			padding: 20px;
			display: block;
			span{
				position: relative;
				left: 0;
				transition: 0.4s ease;
			}
			&:hover{
				img,
				h3{
					opacity: 0.7;
				}
				span{
					opacity: 0.7;
					position: relative;
					left: 5px;
				}
			}
		}
	}
	.index-panel-img{
		position: relative;
		margin: 0 0 10px;
		border: 2px solid #ccc;
		img{
			vertical-align: bottom;
			position: relative;
			transition: 0.4s ease;
			width: 100%;
			max-width: 100%;
		}
	}
}

.pc-hide{
	display: none;
}
.sp-hide{
	display: block;
}
@media only screen and (max-width:640px){
	section.content img{
		max-width: 100%;
	}
	section.content{
		.index-panel{
			justify-content: space-between;
			margin: 0 0 80px;
			.index-panel-item{
				width: 48.5%;
				margin: 0 2% 15px 0;
				a{
					padding: 10px;
				}
				h3{
					font-size: 16px;
				}
				&:nth-of-type(2n){
					margin: 0 0 15px 0;
				}
				&:nth-of-type(3n){
					margin: 0 3% 15px 0;
				}
				&:nth-of-type(6n){
					margin: 0 0 15px 0;
				}
			}
		}
	}


	.inplant-box{
		margin: 0 auto 40px;
		h2{
			font-size: 16px;
		}
	}
	.top,
	.greeting,
	.item-page,
	.access,
	.first{
		.inplant-list{
			flex-wrap: wrap;
		}
		.inplant-item{
			width: 50%;
			margin: 0 0 15px;
			&:last-of-type{
				margin: 0;
			}
		}
	}
	.greeting,
	.item-page,
	.access,
	.first{
		.inplant-box{
			margin: 20px auto 0;
		}
	}



	.map-box{
		ul{
			flex-direction: column;
			li{
				width: 100%;
				iframe{
					height: 250px;
				}
				&:nth-of-type(1){
					margin: 0 0 15px;
				}
			}
		}
	}

	section.content{
		padding: 0;
		margin: 0;
	}

	.top{
		#mainNav{
			.panel{
				& > ul{
					& >	li{
						&:nth-of-type(1){
							& > a{
								color: #000;
							}
						}
					}
				}
			}
		}
	}

	.greeting{
		#mainNav{
			.panel{
				& > ul{
					& >	li{
						&:nth-of-type(2){
							& > a{
								color: #000;
							}
						}
					}
				}
			}
		}
	}

	.item-page{
		#mainNav{
			.panel{
				& > ul{
					& >	li{
						&:nth-of-type(3){
							& > a{
								color: #000;
							}
						}
					}
				}
			}
		}
	}
	.first{
		#mainNav{
			.panel{
				& > ul{
					& >	li{
						&:nth-of-type(4){
							& > a{
								color: #000;
							}
						}
					}
				}
			}
		}
	}
	.access{
		#mainNav{
			.panel{
				& > ul{
					& >	li{
						&:nth-of-type(5){
							& > a{
								color: #000;
							}
						}
					}
				}
			}
		}
	}


	.js-header{
		margin: 0 auto 170px;
	}
	body{
		font-size: 14px;
	}
	.pc-hide{
		display: block;
	}
	.sp-hide{
		display: none;
	}
	#mainBanner{
		.slogan{
			bottom: 40px;
			p{
				font-size: 16px;
			}
		}
	}

	.time-table{
		tbody{
			td{
				padding: 7px;
				font-size: 14px;
				vertical-align: middle;
			}
		}
	}

	.band{
		padding: 2px 0;
		p{
			width: 100%;
			padding: 0 10px;
			font-size: 14px;
		}
	}
	header#header{
		flex-direction: column;
		margin: auto;
		h1{
	    padding: 15px 0 5px 15px;
		}
	}
	.contact-title{
		width: 280px;
		margin: 0 auto 5px;
	}

	.contact-inner{
		flex-direction: column;
		align-items: center;
	}
	.contact-item{
		&:nth-of-type(1){
			margin: 0 0 5px;
		}
	}

	.message-box{
		width: 100%;
		ul{

			li{
				height: auto;
        padding: 0 0 33.5%;
				p{
					top: 20%;
				}
				img{
					width: 100%;
					max-width: 100%;
				}
			}
		}
	}

	.grid-two{
		li{
			width: 50%;
		}
	}
	.arrow-list{
		flex-direction: column;
		li{
			width: 100%;
			margin: 0 0 40px;

			&::after{
				bottom: -50px;
				left: 50%;
				transform: translateX(-50%) rotate(90deg);
			}
		}
	}


	.u-flex{
		flex-direction: column;
		img{
			margin: 15px auto 5px;
			display: block;
			& + p{
				text-align: center;
			}
		}
	}

	#mainBanner{
		width: 100%;
	}
	.news-box,
	.note-box,
	.dr-box,
	.banner-list{
		width: 100%;
	}

	.c-modal__contents {
    max-width: 100%;
    padding: 20px;
  }
	.c-modal__button::before{
		top: -10px;
		left: 17px;
		width: 17px;
	}

	nav#mainNav{
		.panel{
			li{
				position: relative;
				& > a{
					position: relative;
					z-index: 10;
					// border-bottom: none;
					strong{
						&::before{
							content: "→ ";
						}
					}
					&.js-toggle-active{
						strong{
							&::before{
								content: "↓ ";
							}
						}
					}
				}
				.megamenu{
					position: static;
					pointer-events: auto;
					opacity: 1;
					display: none;
					transition: none;
					ul{
						flex-direction: column;
						border: none;
					}
					li{
						width: 100%;
						// height: 30px;
						border: none;
						border-bottom: 1px solid #fff;
					}
					a{
						padding: 10px 10px 10px 20px;
						border: none;

					}
				}


			}
		}

	}

	.info{
		padding: 0 10px;
		margin: 0 0 5px;
		text-align: right;
	}
	.logo, .info .tel{
		font-size: 17px;
	}

	.fixed-banner{
		display: block;
		position: fixed;
		bottom: 0;
		right: 0;
		width: 100%;
		padding: 5px 0;
    transform: translateY(130px);
		ul{
			flex-direction: column;
		}
		li{
			margin: 0 0 0;
			width: 100%;
			a{
				background-color: #ed6639;
				display: block;
				padding: 4px 7px 4px 15px;
				width: 90%;
				color: #fff;
				transition: 0.4s ease;
				background-position: right 45px center;
				font-size: 13px;
				background-image: none;
				&:hover{
					background-color: #f29373;
					opacity: 1;
				}
				position: relative;

				&::after{
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: 15px;
					font-size: 20px;
					content: '→';
				}

			}
			&:nth-of-type(1){
				margin: 0 0 5px;
				border: none;
				a{
					// background-image: none;
					border-right: 1px solid #fff;
				}
			}
			&:nth-of-type(2) a{
				// background-image: none;
			}
		}
	}

.android{
	.fixed-banner{
		ul{
			li{
				a{
					font-size: 10px;
				}
			}
		}
	}
}

	.slick-dots li button{
		transform: scale(0.7);
	}

	.note-box{
    margin: 22px auto 20px;
		p{
			a{
				font-size: 14px;
			}
		}
	}

	.message{
		margin: 30px auto;
		padding: 0 10px;
		img{
			width: 50%;
		}
		p{
			font-size: 12px;
		}
	}

	.news-box{
		h2{
			margin: 0 0 10px;
			font-size: 16px;
		}
	}
	.news-content{
		padding: 10px;
	}
	.news-item{
		margin: 0 0 10px;
		padding: 0 0 10px;
	}

	.dr-list{
		flex-direction: column;
	}
	.dr-item{
		margin: 0 0 15px;
	}
	.banner-list{
    // margin: 0 auto 120px;
	}
	.banner-item{
		width: 50%;
		&:last-of-type{
			margin: 0;
		}
	}

	.breadcrumb{
		margin: 0 0 15px;
	}


	.staff-list{
		flex-wrap: wrap;
		li{
			width: 48.5%;
	    margin: 0 3% 15px 0;
			&:nth-of-type(2n){
				margin: 0 0 15px;
			}
		}
	}


	#footer{
		.info{
			text-align: center;
			border-bottom: 1px solid #aaa;
			padding: 0 0 10px;
			margin: 0 0 10px;
			&:last-of-type{
				border: none;
				padding: 0;
				margin: 0;
			}
		}
	}

}
